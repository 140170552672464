@import "../../global/mixins";

.carouselContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    // grid-column: span 12; 

    &[data-size="20"] {
        grid-column: span 2;
    }

    &[data-size="25"] {
        grid-column: span 3;
    }

    &[data-size="33"] {
        grid-column: span 4;
    }

    &[data-size="50"] {
        grid-column: span 6;
    }

    &[data-size="66"] {
        grid-column: span 8;
    }

    &[data-size="75"] {
        grid-column: span 9;
    }

    &[data-size="100"] {
        grid-column: span 12;
    }

    :global {
        .swiper {
            width: 100%;
        }
    }

    .swiperNavigation {
        display: flex;
        align-items: center;
        justify-self: center;
        margin: 0 auto;
        position: relative;

        :global {

            .swiper-button-next,
            .swiper-button-prev {
                width: getRem(12);
                height: getRem(44);
                background: none;
                border: none;
                flex-shrink: 0;
                margin: 0;
                padding: 0;
                position: relative;
                top: 0;
                left: 0;
                right: 0;
                // transform: translateY(-50%);

                &:before,
                &:after {
                    display: none;
                }

                span {
                    width: 100%;
                    height: 100%;
                    @include font(2, 2);
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                }
            }

            .swiper-button-next {
                svg {
                    transform: rotate(180deg);
                }
            }
        }

        .slideNum {
            width: getRem(40);
            height: getRem(40);
            border: 2px solid var(--yellow);
            border-radius: 50%;
            @include font(28, 36);
            font-weight: bold;
            margin: 0 getRem(24);
            text-align: center;
        }

        .pauseButton {
            width: getRem(36);
            height: getRem(36);
            background: none;
            border: 0;
            cursor: pointer;
            flex-shrink: 0;
            padding: 0;
            position: absolute;
            top: 50%;
            right: getRem(-40);
            transform: translateY(-50%);
    
            svg {
              width: 100%;
              height: 100%;
            }
    
            span {
              width: 100%;
              height: 100%;
              @include font(2, 2);
              opacity: 0;
              position: absolute;
              top: 0;
              left: 0;
              z-index: -1;
            }
          }
    }

    h2,
    h3,
    h4 {
        text-align: center;
    }
}