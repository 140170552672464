@import "../../global/mixins";

.sliderContent {

	.wrapper {
		display: grid;
		grid-template-columns: 51% 49%;

		@include breakpoint(mobile) {
			display: block;
		}
	}

	:global {
		.swiper {
			width: 100%;
		}

		.swiper-button-disabled {
			opacity: .5;
		}

		.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
			background: var(--black);
		}
	}

	.textSwiper {
		background: var(--yellow);
		padding: getRem(80) 0 getRem(56);

		@include breakpoint(mobile) {
			padding: getRem(24) 0;
		}

		:global {
			.swiper-slide {
				padding-bottom: getRem(80);

				@include breakpoint(mobile) {
					padding-bottom: getRem(16);
				}
			}
		}
	}

	.textContainer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0 getRem(100);

		@include breakpoint(tablet) {
			padding: 0 getRem(48);
		}

		@include breakpoint(mobile) {
			padding: 0 getRem(24);
		}

		.subTitle,
		.subTitle * {
			@include font(36, 42);
			font-weight: bold;
			margin: 0;

			@include breakpoint(tablet) {
				@include font(28, 36);
			}

			@include breakpoint(mobile) {
				@include font(24, 32);
			}
		}

		.description,
		.description * {
			@include font(18, 24);
		}

		.description {
			margin: getRem(16) 0 0;
		}

		.description * {
			margin: 0;
		}

		:global {
			.btn {
				@include font(16, 28);
				margin: getRem(16) auto 0 0;
				padding: getRem(8) getRem(16);

				&:after {
					background-color: #FFFFFF;
				}

				&:hover {
					color: var(--red);
				}
			}
		}
	}

	.imageSwiper {
		@include breakpoint(mobile) {
			aspect-ratio: 1;
		}
	}

	.imageContainer {
		width: 100%;
		height: 100%;
		position: relative;
	}

	.thumbnailContainer {
		width: 100%;
		height: 100%;
		position: relative;

		button {
			width: 100%;
			height: 100%;
			background: transparent;
			border: none;
			cursor: pointer;
			// opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;

			span {
				opacity: 0;
				position: absolute;
				top: 0;
				left: 0;
				z-index: -1;
			}

			svg {
				width: getRem(64);
				height: auto;
				border-radius: 50%;
				border: 2px solid var(--red);
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);

				path {
					fill: var(--red);
				}
			}
		}
	}

	.navigation {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 getRem(100);

		@include breakpoint(mobile) {
			width: 100%;
		}
	}

	:global {

		.swiper-button-prev,
		.swiper-button-next {
			position: relative;
			width: getRem(8);
			height: getRem(15);
			margin-top: 0;
			background-color: transparent;
			border: 0;
			border-radius: 0;
			appearance: none;

			svg {
				width: 100%;
				min-width: getRem(8);
				height: auto;
				display: block;
			}

			span {
				border: 0 !important;
				clip: rect(1px, 1px, 1px, 1px) !important;
				clip-path: inset(50%) !important;
				height: 1px !important;
				margin: -1px !important;
				overflow: hidden !important;
				padding: 0 !important;
				position: absolute !important;
				width: 1px !important;
				white-space: nowrap !important;
			}
		}

		.swiper-button-prev {
			margin-right: getRem(16);

			svg {
				transform: rotate(180deg);
			}
		}

		.swiper-button-next {
			margin-left: getRem(16);
		}

		.pause-button {
			width: getRem(36);
			height: getRem(36);
			background: none;
			border: 0;
			cursor: pointer;
			flex-shrink: 0;
			padding: 0;
			position: relative;

			svg {
				width: 100%;
				height: 100%;
			}

			span {
				width: 100%;
				height: 100%;
				@include font(2, 2);
				opacity: 0;
				position: absolute;
				top: 0;
				left: 0;
				z-index: -1;
			}
		}
	}

	.swiperPagination {
		width: getRem(68);
		height: getRem(4);
		background: var(--white);
		margin: auto getRem(8);
		position: relative;
	}

	.slideNumber {
		@include font(14, 18);
		font-weight: 700;
		letter-spacing: 1.75px;
		margin: 0;
	}

	.swiper {
		width: 100%;
		max-width: 100vw;
		overflow: visible;
		outline: 1px solid red;
	}

	.swiper-slide {
		opacity: 0;
		transition: all 0s ease-in-out;
		transition-delay: 0.3s;

		&.swiper-slide-active {
			opacity: 1;

			&+.swiper-slide,
			&+.swiper-slide+.swiper-slide,
			&+.swiper-slide+.swiper-slide+.swiper-slide {
				opacity: 1;
			}
		}
	}
}