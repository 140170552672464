@import "../../global/mixins";

.designHomepage {
    margin-top: calc(var(--nav-height));
    padding: 0 0 getRem(64);

    .wrapper {
        max-width: getRem(1240);
        margin: auto;
        padding: 0;
    }

    h2 {
        @include font(64, 72);
        margin: 0 0 getRem(48);
        text-align: center;

        @include breakpoint(mobile) {
            @include font(42, 56);
        }
    }

    :global {

        .swiper-button-prev,
        .swiper-button-next {
            background: none;
            border: none;
            cursor: pointer;
            margin: 0;
            padding: 0;
            position: relative;
            top: 0;
            left: 0;

            svg {
                width: 100%;
                height: auto;
            }

            span {
                font-size: 2px;
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
            }

            &:after {
                display: none;
            }
        }

        .hasBorder {
            outline: 1px solid #E5E5E5;
        }
    }
}

.banner {

    .wrapper {
        // max-width: getRem(1440);
        display: grid;
        grid-template-columns: 51% 49%;

        @include breakpoint(mobile) {
            display: block;
        }
    }

    :global {
        .swiper {
            width: 100%;
        }

        .swiper-button-disabled {
            opacity: .5;
        }

        .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
            background: var(--black);
        }
    }

    .textSwiper {
        background: var(--yellow);
        padding: getRem(80) 0 getRem(56);

        @include breakpoint(mobile) {
            padding: getRem(24) 0;
        }

        :global {
            .swiper-slide {
                padding-bottom: getRem(80);

                @include breakpoint(mobile) {
                    padding-bottom: getRem(16);
                }
            }
        }
    }

    .textContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 getRem(100);

        @include breakpoint(tablet) {
            padding: 0 getRem(48);
        }

        @include breakpoint(mobile) {
            padding: 0 getRem(24);
        }

        .subTitle,
        .subTitle * {
            @include font(36, 42);
            font-weight: bold;
            margin: 0;

            @include breakpoint(tablet) {
                @include font(28, 36);
            }

            @include breakpoint(mobile) {
                @include font(24, 32);
            }
        }

        .description,
        .description * {
            @include font(18, 24);
        }

        .description {
            margin: getRem(16) 0 0;
        }

        .description * {
            margin: 0;
        }

        :global {
            .btn {
                @include font(16, 28);
                margin: getRem(16) auto 0 0;
                padding: getRem(8) getRem(16);

                &:after {
                    background-color: #FFFFFF;
                }

                &:hover {
                    color: var(--red);
                }
            }
        }
    }

    .imageSwiper {
        @include breakpoint(mobile) {
            aspect-ratio: 1;
        }
    }

    .imageContainer {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .thumbnailContainer {
        width: 100%;
        height: 100%;
        position: relative;

        button {
            width: 100%;
            height: 100%;
            background: transparent;
            border: none;
            cursor: pointer;
            // opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

            span {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
            }

            svg {
                width: getRem(64);
                height: auto;
                border-radius: 50%;
                border: 2px solid var(--red);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                path {
                    fill: var(--red);
                }
            }
        }
    }

    .navigation {
        display: flex;
        align-items: center;
        padding: 0 getRem(100);

        @include breakpoint(mobile) {
            width: 100%;
            justify-content: center;
        }
    }

    :global {

        .swiper-button-prev,
        .swiper-button-next {
            width: getRem(8);
            height: getRem(18);
        }

        .swiper-button-prev {
            margin-right: getRem(8);

            svg {
                transform: rotate(180deg);
            }
        }

        .swiper-button-next {
            margin-left: getRem(8);
        }

        .pause-button {
            width: getRem(36);
            height: getRem(36);
            background: none;
            border: 0;
            cursor: pointer;
            flex-shrink: 0;
            padding: 0;
            position: relative;

            svg {
                width: 100%;
                height: 100%;
            }

            span {
                width: 100%;
                height: 100%;
                @include font(2, 2);
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
            }
        }
    }

    .swiperPagination {
        width: getRem(68);
        height: getRem(4);
        background: var(--white);
        margin: auto getRem(8);
        position: relative;
    }

    .slideNumber {
        @include font(14, 18);
        font-weight: 700;
        letter-spacing: 1.75px;
        margin: 0;
    }
}

.contentGrid {
    padding: getRem(80) 0 0;

    .wrapper {
        max-width: getRem(1240);
        // display: grid;
        // grid-gap: getRem(24) getRem(20);
        // grid-template-columns: repeat(12, 1fr);
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: getRem(72) getRem(48);
        margin: auto;
        padding-top: 5rem;

        @media (max-width: 1330px) {
            padding: 5rem 2rem 0;
        }

        @include breakpoint(mobile) {
            gap: getRem(24) getRem(8);
        }

        &:first-child {
            padding-top: 0;
        }

        &.sectionTitle {
            &+.wrapper {
                padding-top: 0;
            }
        }

        &>* {
            width: 100%;
            //grid-column: span 12;

            &[data-size="20"] {
                //grid-column: span 2;
                //width: calc(20% - 48px);
				flex: 0 0 calc(20% - getRem(48));

                @include breakpoint(tablet) {
                    //width: calc(50% - 2rem);
                    //grid-column: span 6;
					flex: 0 0 calc(50% - getRem(48));
                }

                @include breakpoint(mobile) {
                    //width: 100%;
                    //grid-column: span 12;
					flex: 0 0 100%;
                }
            }

            &[data-size="25"] {
                //grid-column: span 3;
                //width: calc(25% - 48px);
				flex: 0 0 calc(25% - getRem(48));

                @include breakpoint(tablet) {
                    //width: calc(50% - 2rem);
                    //grid-column: span 6;
					flex: 0 0 calc(50% - getRem(48));
                }

                @include breakpoint(mobile) {
                    //width: 100%;
                    //grid-column: span 12;
					flex: 0 0 100%;
                }
            }

            &[data-size="33"] {
                //grid-column: span 4;
                //width: calc(33% - 48px);
				flex: 0 0 calc(33% - getRem(48));

                @include breakpoint(tablet) {
                    //width: calc(50% - 2rem);
                    //grid-column: span 6;
					flex: 0 0 calc(50% - getRem(48));
                }

                @include breakpoint(mobile) {
                    //width: 100%;
                    //grid-column: span 12;
					flex: 0 0 100%;
                }
            }

            &[data-size="50"] {
                //grid-column: span 6;
                //width: calc(50% - 48px);
				flex: 0 0 calc(50% - getRem(48));

                @include breakpoint(mobile) {
                    //width: 100%;
                    //grid-column: span 12;
					flex: 0 0 100%;
                }
            }

            &[data-size="66"] {
                //grid-column: span 8;
                //width: calc(66% - 48px);
				flex: 0 0 calc(66% - getRem(48));

				@include breakpoint(tablet) {
					flex: 0 0 calc(50% - getRem(48));
                }

                @include breakpoint(mobile) {
                    //width: 100%;
                    //grid-column: span 12;
					flex: 0 0 100%;
                }
            }

            &[data-size="75"] {
                //grid-column: span 9;
                //width: calc(75% - 48px);
				flex: 0 0 calc(75% - getRem(48));

				@include breakpoint(tablet) {
					flex: 0 0 calc(50% - getRem(48));
                }

                @include breakpoint(mobile) {
                    //width: 100%;
                    //grid-column: span 12;
					flex: 0 0 100%;
                }
            }

            &[data-size="100"] {
                //width: 100%;
                //grid-column: span 12;
				flex: 0 0 100%;

                @include breakpoint(mobile) {
                    //grid-column: span 12;
                }
            }
        }
    }

	.row {
		& > * {
			grid-column: span 6;
			margin: auto 0;
	
			&[data-size="20"] {
				grid-column: span 2;
	
				@include breakpoint(tablet) {
					grid-column: span 6;
				}
			
				@include breakpoint(mobile) {
					grid-column: span 12;
				}
			}
	
			&[data-size="25"] {
				grid-column: span 3;
	
				@include breakpoint(tablet) {
					grid-column: span 6;
				}
			
				@include breakpoint(mobile) {
					grid-column: span 12;
				}
			}
	
			&[data-size="33"] {
				grid-column: span 4;
	
				@include breakpoint(tablet) {
					grid-column: span 6;
				}
			
				@include breakpoint(mobile) {
					grid-column: span 12;
				}
			}
	
			&[data-size="50"] {
				grid-column: span 6;
			
				@include breakpoint(mobile) {
					grid-column: span 12;
				}
			}
	
			&[data-size="66"] {
				grid-column: span 8;
			
				@include breakpoint(mobile) {
					grid-column: span 12;
				}
			}
	
			&[data-size="75"] {
				grid-column: span 9;
			
				@include breakpoint(mobile) {
					grid-column: span 12;
				}
			}
	
			&[data-size="100"] {
				grid-column: span 12;
			}
		}
	}
	
	.grid {
		display: grid;
		grid-gap: getRem(68) getRem(20);
		grid-template-columns: repeat(12, 1fr);
		margin-top: getRem(64);
	
		.gridItem {
			&[data-size="20"] {
				grid-column: span 2;
	
				@include breakpoint(tablet) {
					grid-column: span 6;
				}
			
				@include breakpoint(mobile) {
					grid-column: span 12;
				}
			}
	
			&[data-size="25"] {
				grid-column: span 3;
	
				@include breakpoint(tablet) {
					grid-column: span 6;
				}
			
				@include breakpoint(mobile) {
					grid-column: span 12;
				}
			}
	
			&[data-size="33"] {
				grid-column: span 4;
	
				@include breakpoint(tablet) {
					grid-column: span 6;
				}
			
				@include breakpoint(mobile) {
					grid-column: span 12;
				}
			}
	
			&[data-size="50"] {
				grid-column: span 6;
			
				@include breakpoint(mobile) {
					grid-column: span 12;
				}
			}
	
			&[data-size="66"] {
				grid-column: span 8;
			
				@include breakpoint(mobile) {
					grid-column: span 12;
				}
			}
	
			&[data-size="75"] {
				grid-column: span 9;
			
				@include breakpoint(mobile) {
					grid-column: span 12;
				}
			}
	
			&[data-size="100"] {
				grid-column: span 12;
			}
		}
	}

    .gridItem {
        margin: 0;

        .imageContainer {
            aspect-ratio: 1.37;
            position: relative;
        }

        .cta {
            color: var(--red);
            display: inline-block;
            @include font(16, 24);
            font-weight: 700;
            margin-top: getRem(12);

            &:hover {
                text-decoration: underline;
            }

            &:focus {
                outline: 1px solid var(--red);

                &:focus-visible {
                    outline: none;
                }
            }
        }
    }

    :global {
        .imageItem {
            border-radius: 0 0 4px 4px;

            &>a {
                width: 100%;
                aspect-ratio: 1.78;
                display: inline-block;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    position: absolute;
                }
            }

            &>div {
                // padding: 1.25rem 1.5rem;
                margin-top: 1.25rem;

                h3 {
                    margin: 0 0 getRem(12);
                }

                &>p {
                    font-size: 1rem;
                    line-height: 1.4em;
                    // font-weight: 700;
                    margin: 0;

                    &+.textLink {
                        margin-top: 1rem;
                    }
                }

                img {
                    max-width: 100%;
                }

                .textLink {
                    color: var(--red);
                    display: inline-block;
                    font-size: 1rem;
                    line-height: 1.5em;
                    font-weight: 700;
                }
            }
        }
    }
}

.gallerySection {
    overflow: hidden;
    padding: getRem(80) 0 getRem(40);

    @include breakpoint(mobile) {
        padding: getRem(32) 0;
    }

    .galleryWrapper {
        max-width: getRem(1296);
        overflow: hidden;
        padding: 0 getRem(28);
    }

    :global {
        .swiper {
            // width: calc(100% - 64px);
            width: 100%;
            max-width: 100vw;
            // margin: 0 getRem(32);
            overflow: visible;

            @include breakpoint(mobile) {
                // width: calc(100vw - 48px);
                // margin: 0 getRem(24);
                // padding: 0 getRem(24);
            }
        }

        .swiper-slide {
            opacity: 0;
            transition: all 0s ease-in-out;
            transition-delay: 0.3s;

            &.swiper-slide-active {
                opacity: 1;

                & + .swiper-slide,
                & + .swiper-slide + .swiper-slide,
                & + .swiper-slide + .swiper-slide + .swiper-slide {
                    opacity: 1;
                }
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            width: getRem(56);
            height: getRem(56);
            border-radius: 50%;
            border: none;
            // background: #FFFFFF;
            outline: none;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transition: all .2s ease-in-out;

            &:after {
                display: none;
            }

            svg {
                width: getRem(56);
                height: getRem(56);

                path {
                    fill: var(--yellow);
                    transition: all .2s ease-in-out;
                }
            }

            &:hover {
                svg {
                    path {
                        fill: var(--red);
                    }
                }
            }
        }

        .swiper-button-prev {
            left: getRem(-24);
        }

        .swiper-button-next {
            left: auto;
            right: getRem(-24);

            svg {
                transform: rotate(180deg);
            }
        }
    }

    .imageContainer {
        aspect-ratio: 1;
        border-radius: getRem(4);
        cursor: pointer;
        overflow: hidden;
        position: relative;
    }
}