@import "../../global/mixins";

.bannerContent {
    grid-column: span 12;
    overflow: hidden;
    position: relative;

    // &:before {
    //     content: '';
    //     width: 100%;
    //     height: 100%;
    //     background: rgba(0,0,0,.1);
    //     display: inline-block;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     z-index: 1;
    // }

    .imageContainer {
        width: 100%;
        // height: 100%;
        // position: absolute;
        top: 0;
        left: 0;
        z-index: 0;

        &Inner {
            position: relative;
        }
    }

    figure {
        img {
            width: 100%;
        }
    }

    video {
        width: 100%;
    }

    .textContainer {
        max-width: 60%;
        max-height: 100%;
        margin: 0 auto;
        padding: getRem(80) getRem(56);
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        z-index: 2;

        &.left {
            left: 0;
            transform: none;
        }

        &.right {
            left: auto;
            right: 0;
            transform: none;
        }

        * {
            color: var(--white);
        }

        figure {
            margin: 0;
        }

        a {
            border-bottom: 2px solid var(--red);
            color: var(--yellow);
            line-height: 100%;
        }
    }
}